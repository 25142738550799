
import { Vue, Component } from "vue-property-decorator";
import { mapMutations } from "vuex";
import {
    FilterFormInterface,
    ResponseDataDates as responseDates,
    Stats,
    Chart,
    ChartError
} from "@/interfaces";

import * as types from "@/store/types";
import { sleep } from "@/utils";
import { TOKEN } from "@/services/auth/auth-service";

@Component({
    components: {
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        CardStats: () => import("@/components/layout/CardStats.vue"),
        CardProgress: () => import("@/components/layout/CardProgress.vue"),
        PlotlyChart: () => import("@/components/charts/PlotlyChart.vue"),
        TabItem: () => import("@/components/layout/TabItem.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    }
})
export default class Overview extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    filterFormPanelShow: boolean | null = false;
    filterForm: FilterFormInterface = {
        company: "",
        client: "",
        installation: "",
        modbus: "",
        valid: true,
        box: "",
        element: "",
        periodType: "daily",
        start: undefined,
        end: undefined,
        window: "1h",
        aggregation: "mean",
        comparative: false,
        days: ""
    };
    showFilter = false;
    showAll = false;
    tensionStats: Stats = {};
    tensionChart: Chart = { data: [] };
    currentStats: Stats = {};
    currentInterruptor = 43.5;
    currentChart: Chart = { data: [] };
    activePowerStats: Stats = {};
    activePowerChart: Chart = { data: [] };
    powerFactorChart: Chart = { data: [] };
    thdvStats: Stats = {};
    thdvChart: Chart = { data: [] };
    thdiStats: Stats = {};
    thdiChart: Chart = { data: [] };

    errors: ChartError[] = [];

    lotura: any = {};

    // mounted() {
    //     this.getBucketBoundDates();
    // }

    activated() {
        if (this.errors.length) {
            this.fetchData();
        }
    }

    /**
     * Methods
     */

    async getLotura() {
        this.setProgressBar(true);
        this.$api
            .getLotura<Chart>()
            .then(response => {
                this.setProgressBar(false);
                this.lotura = response.data;
            })
            .catch(error => {
                this.setProgressBar(false);
                console.error("error", error);
                this.showError(
                    error.message
                        ? error.message
                        : "Error getting default filter."
                );
            });
    }

    async getBucketBoundDates() {
        this.setProgressBar(true);
        try {
            this.$api
                .getBoxBoundDates<responseDates>(
                    this.filterForm.box,
                    this.filterForm.analyzer,
                    this.filterForm.element
                )
                .then(response => {
                    this.setProgressBar(false);
                    this.filterForm.start = new Date(
                        new Date(response.data.dates.end).setDate(
                            new Date(response.data.dates.end).getDate() - 1
                        )
                    )
                        .toISOString()
                        .split("T")[0];
                    this.filterForm.end = new Date(response.data.dates.end)
                        .toISOString()
                        .split("T")[0];
                    this.fetchData();
                })
                .catch(error => {
                    this.setProgressBar(false);
                    console.error("error", error);
                    this.showError(
                        error.message
                            ? error.message
                            : "Error getting default filter."
                    );
                });
        } catch (error) {
            this.setProgressBar(false);
            console.error("error", error);
            this.showError("Error getting box bound dates.");
        }
    }

    async fetchData() {
        this.setProgressBar(true);
        /**
         * Requests
         */

        this.tensionStats = {};
        this.tensionChart = { data: [] };
        this.currentStats = {};
        this.currentInterruptor = 0;
        this.currentChart = { data: [] };
        this.activePowerStats = {};
        this.activePowerChart = { data: [] };
        this.powerFactorChart = { data: [] };
        this.thdvStats = {};
        this.thdvChart = { data: [] };
        this.thdiStats = {};
        this.thdiChart = { data: [] };

        // const currentStatsReq = await this.$api
        //     .getCurrentStats<responseStats>(
        //         this.filterForm.box,
        //         this.filterForm.analyzer,
        //         this.filterForm.element,
        //         this.filterForm.start,
        //         this.filterForm.end,
        //         this.filterForm.window,
        //         this.filterForm.aggregation
        //     )
        //     .then(response => {
        //         return response;
        //     })
        //     .catch(error => {
        //         return error;
        //     });

        //     const currentStreamReq = await this.$api
        //         .getCurrentStream<responseChart>(
        //             this.filterForm.box,
        //             this.filterForm.analyzer,
        //             this.filterForm.element,
        //             this.filterForm.start,
        //             this.filterForm.end,
        //             this.filterForm.window,
        //             this.filterForm.aggregation
        //         )
        //         .then(response => {
        //             return response;
        //         })
        //         .catch(error => {
        //             return error;
        //         });

        //     const tensionStatsReq = await this.$api
        //         .getTensionStats<responseStats>(
        //             this.filterForm.box,
        //             this.filterForm.analyzer,
        //             this.filterForm.element,
        //             this.filterForm.start,
        //             this.filterForm.end,
        //             this.filterForm.window,
        //             this.filterForm.aggregation
        //         )
        //         .then(response => {
        //             return response;
        //         })
        //         .catch(error => {
        //             return error;
        //         });

        //     const tensionStreamReq = await this.$api
        //         .getTensionStream<responseChart>(
        //             this.filterForm.box,
        //             this.filterForm.analyzer,
        //             this.filterForm.element,
        //             this.filterForm.start,
        //             this.filterForm.end,
        //             this.filterForm.window,
        //             this.filterForm.aggregation
        //         )
        //         .then(response => {
        //             return response;
        //         })
        //         .catch(error => {
        //             return error;
        //         });

        //     const activePowerStatsReq = await this.$api
        //         .getActivePowerStats<responseStats>(
        //             this.filterForm.box,
        //             this.filterForm.analyzer,
        //             this.filterForm.element,
        //             this.filterForm.start,
        //             this.filterForm.end,
        //             this.filterForm.window,
        //             this.filterForm.aggregation
        //         )
        //         .then(response => {
        //             return response;
        //         })
        //         .catch(error => {
        //             return error;
        //         });

        //     const activePowerStreamReq = await this.$api
        //         .getActivePowerStream<responseChart>(
        //             this.filterForm.box,
        //             this.filterForm.analyzer,
        //             this.filterForm.element,
        //             this.filterForm.start,
        //             this.filterForm.end,
        //             this.filterForm.window,
        //             this.filterForm.aggregation
        //         )
        //         .then(response => {
        //             return response;
        //         })
        //         .catch(error => {
        //             return error;
        //         });

        //     const powerFactorReq = await this.$api
        //         .getPowerFactor<responseChart>(
        //             this.filterForm.box,
        //             this.filterForm.analyzer,
        //             this.filterForm.element,
        //             this.filterForm.start,
        //             this.filterForm.end,
        //             this.filterForm.window,
        //             this.filterForm.aggregation
        //         )
        //         .then(response => {
        //             return response;
        //         })
        //         .catch(error => {
        //             return error;
        //         });

        //     const thdvStatsReq = await this.$api
        //         .getTHDvStats<responseStats>(
        //             this.filterForm.box,
        //             this.filterForm.analyzer,
        //             this.filterForm.element,
        //             this.filterForm.start,
        //             this.filterForm.end,
        //             this.filterForm.window,
        //             this.filterForm.aggregation
        //         )
        //         .then(response => {
        //             return response;
        //         })
        //         .catch(error => {
        //             return error;
        //         });

        //     const thdvStreamReq = await this.$api
        //         .getTHDvStream<responseChart>(
        //             this.filterForm.box,
        //             this.filterForm.analyzer,
        //             this.filterForm.element,
        //             this.filterForm.start,
        //             this.filterForm.end,
        //             this.filterForm.window,
        //             this.filterForm.aggregation
        //         )
        //         .then(response => {
        //             return response;
        //         })
        //         .catch(error => {
        //             return error;
        //         });

        //     const thdiStatsReq = await this.$api
        //         .getTHDiStats<responseStats>(
        //             this.filterForm.box,
        //             this.filterForm.analyzer,
        //             this.filterForm.element,
        //             this.filterForm.start,
        //             this.filterForm.end,
        //             this.filterForm.window,
        //             this.filterForm.aggregation
        //         )
        //         .then(response => {
        //             return response;
        //         })
        //         .catch(error => {
        //             return error;
        //         });

        //     const thdiStreamReq = await this.$api
        //         .getTHDiStream<responseChart>(
        //             this.filterForm.box,
        //             this.filterForm.analyzer,
        //             this.filterForm.element,
        //             this.filterForm.start,
        //             this.filterForm.end,
        //             this.filterForm.window,
        //             this.filterForm.aggregation
        //         )
        //         .then(response => {
        //             return response;
        //         })
        //         .catch(error => {
        //             return error;
        //         });

        //     Promise.allSettled([
        //         currentStatsReq,
        //         currentStreamReq,
        //         tensionStatsReq,
        //         tensionStreamReq,
        //         activePowerStatsReq,
        //         activePowerStreamReq,
        //         powerFactorReq,
        //         thdvStatsReq,
        //         thdvStreamReq,
        //         thdiStatsReq,
        //         thdiStreamReq
        //     ])
        //         .then(async results => {
        //             await sleep(500);

        //             const currentStatsRes = results[0];
        //             if (currentStatsRes.status === "fulfilled") {
        //                 this.showAll = true;
        //                 if (currentStatsRes.value.ok) {
        //                     this.currentStats = currentStatsRes.value.data.stats;
        //                 } else {
        //                     this.errors.push({
        //                         chart: "current",
        //                         message: currentStatsRes.value.message
        //                     });
        //                 }
        //             }

        //             const currentStreamRes = results[1];
        //             if (currentStreamRes.status === "fulfilled") {
        //                 if (currentStreamRes.value.ok) {
        //                     this.currentChart = currentStreamRes.value.data.fig;
        //                 } else {
        //                     this.errors.push({
        //                         chart: "current",
        //                         message: currentStreamRes.value.message
        //                     });
        //                 }
        //             }

        //             const tensionStatsRes = results[2];
        //             if (tensionStatsRes.status === "fulfilled") {
        //                 if (tensionStatsRes.value.ok) {
        //                     this.tensionStats = tensionStatsRes.value.data.stats;
        //                 } else {
        //                     this.errors.push({
        //                         chart: "tension",
        //                         message: tensionStatsRes.value.message
        //                     });
        //                 }
        //             }

        //             const tensionStreamRes = results[3];
        //             if (tensionStreamRes.status === "fulfilled") {
        //                 if (tensionStreamRes.value.ok) {
        //                     this.tensionChart = tensionStreamRes.value.data.fig;
        //                 } else {
        //                     this.errors.push({
        //                         chart: "tension",
        //                         message: tensionStreamRes.value.message
        //                     });
        //                 }
        //             }

        //             const activePowerStatsRes = results[4];
        //             if (activePowerStatsRes.status === "fulfilled") {
        //                 if (activePowerStatsRes.value.ok) {
        //                     this.activePowerStats =
        //                         activePowerStatsRes.value.data.stats;
        //                 } else {
        //                     this.errors.push({
        //                         chart: "activePower",
        //                         message: activePowerStatsRes.value.message
        //                     });
        //                 }
        //             }

        //             const activePowerStreamRes = results[5];
        //             if (activePowerStreamRes.status === "fulfilled") {
        //                 if (activePowerStreamRes.value.ok) {
        //                     this.activePowerChart =
        //                         activePowerStreamRes.value.data.fig;
        //                 } else {
        //                     this.errors.push({
        //                         chart: "activePower",
        //                         message: activePowerStreamRes.value.message
        //                     });
        //                 }
        //             }

        //             const powerFactorRes = results[6];
        //             if (powerFactorRes.status === "fulfilled") {
        //                 if (powerFactorRes.value.ok) {
        //                     this.powerFactorChart = powerFactorRes.value.data.fig;
        //                 } else {
        //                     this.errors.push({
        //                         chart: "powerFactor",
        //                         message: powerFactorRes.value.message
        //                     });
        //                 }
        //             }

        //             const thdvStatsRes = results[7];
        //             if (thdvStatsRes.status === "fulfilled") {
        //                 if (thdvStatsRes.value.ok) {
        //                     this.thdvStats = thdvStatsRes.value.data.stats;
        //                 } else {
        //                     this.errors.push({
        //                         chart: "tdhv",
        //                         message: thdvStatsRes.value.message
        //                     });
        //                 }
        //             }

        //             const thdvStreamRes = results[8];
        //             if (thdvStreamRes.status === "fulfilled") {
        //                 if (thdvStreamRes.value.ok) {
        //                     this.thdvChart = thdvStreamRes.value.data.fig;
        //                 } else {
        //                     this.errors.push({
        //                         chart: "thdv",
        //                         message: thdvStreamRes.value.message
        //                     });
        //                 }
        //             }

        //             const thdiStatsRes = results[9];
        //             if (thdiStatsRes.status === "fulfilled") {
        //                 if (thdiStatsRes.value.ok) {
        //                     this.thdiStats = thdiStatsRes.value.data.stats;
        //                 } else {
        //                     this.errors.push({
        //                         chart: "thdi",
        //                         message: thdiStatsRes.value.message
        //                     });
        //                 }
        //             }

        //             const thdiStreamRes = results[10];
        //             if (thdiStreamRes.status === "fulfilled") {
        //                 if (thdiStreamRes.value.ok) {
        //                     this.thdiChart = thdiStreamRes.value.data.fig;
        //                 } else {
        //                     this.errors.push({
        //                         chart: "thdi",
        //                         message: thdiStreamRes.value.message
        //                     });
        //                 }
        //             }
        //         })
        //         .finally(() => this.setProgressBar(false));
    }

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            this.fetchData();
        } else {
            this.$router.push("/signin");
        }
    }

    formatDates() {
        if (this.filterForm?.start && this.filterForm.end)
            return (
                new Date(this.filterForm?.start).toDateString() +
                " - " +
                new Date(this.filterForm.end).toDateString()
            );
        return "";
    }

    checkChartErrors(chart: string) {
        const error = this.errors.filter(error => error.chart === chart);
        if (error.length && error[0].chart === chart) {
            this.showError(error[0].message);
        }
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    data() {
        return {
            headers: [
                { text: "Fecha", value: "fecha" },
                { text: "Estado", value: "estado" },
                { text: "Ubicación", value: "ubicacion" },
                { text: "Mejora", value: "mejora" },
                { text: "Calisificación", value: "clasificacion" },
                { text: "Feha de Finalización", value: "finalizado" }
            ],
            items: [
                {
                    fecha: "01 Jun 2023",
                    estado: "pendiente",
                    ubicacion:
                        "Erribera Industria Gunea, 1, 20150 Aduna, Guipúzcoa",
                    mejora: "Optimización de Potencia",
                    clasificacion: "recomendable",
                    finalizado: "No Finalizado"
                },
                {
                    fecha: "01 Jun 2023",
                    estado: "pendiente",
                    ubicacion:
                        "Erribera Industria Gunea, 1, 20150 Aduna, Guipúzcoa",
                    mejora: "Optimización de Potencia",
                    clasificacion: "recomendable",
                    finalizado: "No Finalizado"
                },
                {
                    fecha: "01 Jun 2023",
                    estado: "pendiente",
                    ubicacion:
                        "Erribera Industria Gunea, 1, 20150 Aduna, Guipúzcoa",
                    mejora: "Optimización de Potencia",
                    clasificacion: "recomendable",
                    finalizado: "No Finalizado"
                },
                {
                    fecha: "01 Jun 2023",
                    estado: "pendiente",
                    ubicacion:
                        "Erribera Industria Gunea, 1, 20150 Aduna, Guipúzcoa",
                    mejora: "Optimización de Potencia",
                    clasificacion: "recomendable",
                    finalizado: "No Finalizado"
                },
                {
                    fecha: "01 Jun 2023",
                    estado: "pendiente",
                    ubicacion:
                        "Erribera Industria Gunea, 1, 20150 Aduna, Guipúzcoa",
                    mejora: "Optimización de Potencia",
                    clasificacion: "recomendable",
                    finalizado: "No Finalizado"
                }
            ],
            search: ""
        };
    }
}
